.information-page {
  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
    color: $dark-gray;
  }

  a {
    color: $orange;
    font-size: 14px;
    line-height: 18px;
  }
}
