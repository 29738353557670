.our-trademarks {
  &__title {
    margin-bottom: 17px;

    @media (max-width: $mobile-max) {
      margin-bottom: 30px;
    }
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  &__item {
    width: calc(100% / 5 - 16px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    & > img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    @media (max-width: $mobile-max) {
      width: calc(100% / 2 - 16px);
      padding: 6px;
    }
  }

  &__slider {
    .slick-track {
      display: flex;
      align-items: center;
    }
  }
}
