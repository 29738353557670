* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

@font-face {
  font-family: "Mariupol Bold";
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol/Mariupol-Bold.otf");
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Bold.ttf") format("ttf"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Bold.woff2") format("woff2"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Mariupol Medium";
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Medium.otf");
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Medium.ttf") format("ttf"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Medium.woff2") format("woff2"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Mariupol Regular";
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Regular.otf");
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Regular.ttf") format("ttf"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Regular.woff2") format("woff2"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/Mariupol-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Mariupol Symbols";
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/MariupolSymbols.otf");
  src: url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/MariupolSymbols.ttf") format("ttf"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/MariupolSymbols.woff2") format("woff2"), url("/catalog/view/theme/prioritet/stylesheet/fonts/Mariupol/MariupolSymbols.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* ===== fonts =====*/
/* ===== colors =====*/
/* ===== width screen =====*/
@font-face {
  font-family: "icons";
  src: url("fonts/icon-fonts/icons.eot");
  src: url("fonts/icon-fonts/icons.eot?#iefix") format("eot"), url("fonts/icon-fonts/icons.woff2") format("woff2"), url("fonts/icon-fonts/icons.woff") format("woff"), url("fonts/icon-fonts/icons.ttf") format("truetype"), url("fonts/icon-fonts/icons.svg#icons") format("svg");
}
.icon:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-0001-facebook:before {
  content: "\e001";
}

.icon-0002-youtube:before {
  content: "\e002";
}

.icon-0004-benefit2:before {
  content: "\e003";
}

.icon-0005-benefit3:before {
  content: "\e004";
}

.icon-0007-benefit5:before {
  content: "\e005";
}

.icon-0008-benefit6:before {
  content: "\e006";
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Mariupol Regular", sans-serif;
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  background-color: #fff;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-hidden {
    display: none !important;
  }
}

.decorated-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.decorated-title_center {
  justify-content: center;
}
@media (max-width: 767px) {
  .decorated-title_center {
    justify-content: flex-start;
  }
}
.decorated-title::before {
  content: "";
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
  background-color: #FA7A0A;
}
@media (max-width: 767px) {
  .decorated-title::before {
    width: 44px;
    height: 44px;
  }
}
.decorated-title__text {
  font-weight: 700;
  font-size: 34px;
  line-height: 33px;
  text-transform: uppercase;
  color: #303030;
  margin-left: -35px;
}
@media (max-width: 767px) {
  .decorated-title__text {
    font-size: 22px;
    line-height: 21px;
    margin-left: -25px;
  }
}

.text-color_orange {
  color: #FA7A0A !important;
}
.text-color_white {
  color: #fff !important;
}

.colored-link {
  color: #FA7A0A !important;
}
.colored-link:hover {
  color: #FA7A0A !important;
}

.breadcrumb {
  display: none;
}

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open:after {
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-42px/2);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}

.btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  border-radius: 60px;
  background-color: #FA7A0A;
  padding: 22px 40px;
  border: 1px solid #FA7A0A;
  outline: none;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.btn:hover {
  color: #fff;
}

.big-banner {
  position: relative;
}
.big-banner__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-banner .slick-dots {
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.big-banner .slick-dots li {
  width: 11px;
  height: 11px;
  margin: 0;
}
.big-banner .slick-dots li.slick-active {
  width: 15px;
  height: 15px;
}
.big-banner .slick-dots li.slick-active button {
  width: 15px;
  height: 15px;
}
.big-banner .slick-dots li.slick-active button::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #fff;
  opacity: 1;
}
.big-banner .slick-dots li button {
  width: 11px;
  height: 11px;
}
.big-banner .slick-dots li button::before {
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: 1;
}

.big-banner-slide__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  width: 100%;
}
.big-banner-slide__img {
  position: relative;
}
.big-banner-slide__img::before {
  content: "";
  background-color: rgba(17, 17, 17, 0.7);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.big-banner-slide__img img {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .big-banner-slide__img img {
    height: 404px;
    width: auto;
    object-fit: cover;
    object-position: center;
  }
}
.big-banner-slide__header {
  font-weight: 700;
  font-size: 44px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 20px;
  display: block;
}
@media (max-width: 767px) {
  .big-banner-slide__header {
    font-size: 24px;
    line-height: 23px;
  }
}
.big-banner-slide__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  max-width: 724px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .big-banner-slide__description {
    font-size: 16px;
  }
}
.big-banner-slide__btn {
  margin-top: 40px;
}

.mini-banner {
  background-color: #3C3C3C;
}
.mini-banner__box {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 50px;
}
.mini-banner__box::before, .mini-banner__box::after {
  display: none;
}
@media (max-width: 767px) {
  .mini-banner__box {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px 60px;
  }
}
@media (max-width: 1200px) {
  .mini-banner__box_right {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .mini-banner__box_right {
    flex-direction: column-reverse;
    padding: 60px 15px 0;
    gap: 30px;
  }
}
.mini-banner__img {
  position: absolute;
  right: 0;
  z-index: 1;
}
.mini-banner__img img {
  width: 100%;
  max-width: 518px;
  height: auto;
}
.mini-banner__img_right {
  position: static;
  margin-top: -90px;
  margin-bottom: -78px;
}
.mini-banner__img_right img {
  width: 100%;
  max-width: 213px;
  height: auto;
}
@media (max-width: 1200px) {
  .mini-banner__img_right {
    margin-bottom: -60px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .mini-banner__img_right {
    position: static;
    margin-bottom: 0;
    top: 0 !important;
    right: 30px;
  }
}
@media (max-width: 767px) {
  .mini-banner__img {
    position: relative;
    top: -40px;
  }
}
.mini-banner__header {
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}
@media (max-width: 767px) {
  .mini-banner__header {
    font-size: 22px;
  }
}
.mini-banner__btn {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .mini-banner__btn {
    margin: 20px auto 0;
  }
}
.mini-banner__text {
  position: relative;
  z-index: 2;
}
.mini-banner__text_flex-direction-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
@media (max-width: 767px) {
  .mini-banner__text_flex-direction-row {
    flex-direction: column;
    gap: 30px;
  }
  .mini-banner__text_flex-direction-row .header__contacts {
    flex-direction: column;
    gap: 30px;
  }
  .mini-banner__text_flex-direction-row .header__socials {
    display: flex;
  }
}
@media (max-width: 767px) {
  .mini-banner__text {
    text-align: center;
  }
}

.form-builder {
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  max-width: 991px;
  width: 100%;
  margin: 0 auto;
}
.form-builder .box-heading {
  padding: 15px;
  text-align: center;
  background-color: #FA7A0A;
  border-radius: 8px 8px 0 0;
}
.form-builder .box-heading .header-text {
  color: #fff;
}
.form-builder .box-content {
  padding: 15px;
}
.form-builder .box-content .field-block {
  justify-content: space-between;
  align-items: center;
}
.form-builder .box-content .field-block h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.form-builder .box-content .field-block .btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  border-radius: 60px;
  background: #FA7A0A;
  background-color: #FA7A0A;
  padding: 22px 40px;
  border: 1px solid #FA7A0A;
  outline: none;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.form-builder .box-content .field-block .btn:hover {
  color: #fff;
}

#form-builder-61 {
  display: none;
}
#form-builder-61.show {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.4);
}

.shop-address {
  padding: 15px;
  border: 3px dashed #f5881f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 767px) {
  .shop-address {
    flex-direction: column;
  }
}
.shop-address__info {
  width: 35%;
}
@media (max-width: 767px) {
  .shop-address__info {
    width: 100%;
  }
}
.shop-address__map {
  width: 65%;
}
@media (max-width: 767px) {
  .shop-address__map {
    width: 100%;
  }
}
.shop-address__city {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 12px;
  color: #303030;
}
.shop-address__address {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #303030;
}
.shop-address__phones {
  margin-bottom: 10px;
}
.shop-address__phones .phones-block {
  display: none;
}
.shop-address__phones .phones-block.show-phones {
  display: block;
}
.shop-address__phones .get-phones,
.shop-address__phones .hidden-phones {
  cursor: pointer;
  color: #f5881f;
  font-weight: 700;
  font-size: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
.shop-address__phones .hidden-phones {
  margin-top: 10px;
  display: none;
}

.header {
  padding: 10px 0;
}
.header__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 767px) {
  .header__box > div:last-child {
    order: 2;
  }
}
.header__contacts {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
}
.header__phones {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
}
.header__phones a {
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  white-space: nowrap;
}
.header__phones a:hover {
  color: #FA7A0A;
}
@media (max-width: 767px) {
  .header__phones a {
    font-size: 18px;
    line-height: 17px;
  }
}
.header__phones span {
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #7A7A7A;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .header__phones span {
    font-size: 12px;
    line-height: 12px;
  }
}
.header__socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.header__socials .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #FA7A0A;
  border-radius: 50%;
}
.header__socials .icon::before {
  color: #fff;
}
.header__socials .icon.icon-0002-youtube::before {
  font-size: 12px;
}
@media (max-width: 767px) {
  .header__socials {
    display: none;
  }
}
.header #logo {
  margin: 0;
}
.header #logo img {
  max-width: 145px;
  height: auto;
}
@media (max-width: 767px) {
  .header #logo img {
    max-width: 92px;
  }
}
@media (max-width: 767px) {
  .header #logo {
    order: 1;
  }
}
.header .navbar-collapse {
  padding: 0;
  position: relative;
}
.header .navbar-collapse .navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.header .navbar-collapse .navbar-nav::before, .header .navbar-collapse .navbar-nav::after {
  display: none;
}
@media (max-width: 767px) {
  .header .navbar-collapse .navbar-nav {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    padding: 82px 30px 20px;
    position: relative;
    gap: 25px;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .header .megamenu-burger {
    display: block;
    width: 30px;
    height: 20px;
    background-image: url("/image/design/decor/mobile-menu-burger.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.header #megamenu-menu {
  min-height: auto;
  margin: 0;
}
.header #megamenu-menu a {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #303030;
  transition: 0.2s ease-in;
}
.header #megamenu-menu a:hover {
  background-color: transparent;
  color: #FA7A0A;
  transition: 0.2s ease-in;
}
@media (max-width: 767px) {
  .header #megamenu-menu a {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #fff;
  }
}
.header #megamenu-menu .header__contacts {
  display: none;
}
@media (max-width: 767px) {
  .header #megamenu-menu.open-menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 15%;
    bottom: 0;
    background-color: #303030;
    z-index: 10;
  }
  .header #megamenu-menu.open-menu .navbar-collapse {
    display: block;
    overflow: scroll;
    height: 100%;
  }
  .header #megamenu-menu.open-menu .navbar-collapse .header__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
    margin-top: 40px;
  }
  .header #megamenu-menu.open-menu .navbar-collapse .header__contacts .header__phones {
    align-items: flex-start;
  }
  .header #megamenu-menu.open-menu .navbar-collapse .header__contacts .header__phones a {
    font-size: 18px;
    font-weight: 700;
  }
  .header #megamenu-menu.open-menu .navbar-collapse .header__contacts .header__socials {
    display: flex;
  }
}
@media (max-width: 767px) {
  .header .close-menu {
    display: block;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 11;
    outline: none;
    border: none;
    background-color: transparent;
    background-image: url("/image/design/icons/close.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 22px;
    height: 22px;
  }
  .header .top-menu {
    order: 3;
  }
}

.about-us {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-us > div {
  width: 50%;
}
@media (max-width: 767px) {
  .about-us > div {
    width: 100%;
  }
}
.about-us__title {
  margin-bottom: 20px;
}
.about-us__description {
  padding: 0 35px;
}
.about-us__description:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .about-us__description {
    padding: 0;
  }
}
.about-us__img img {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .about-us {
    flex-direction: column;
    gap: 20px;
  }
}

.our-benefits {
  background-color: #F0F0F0;
}
.our-benefits__title {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .our-benefits__title {
    margin-bottom: 30px;
  }
}
.our-benefits__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  column-gap: 20px;
  row-gap: 40px;
}
@media (max-width: 767px) {
  .our-benefits__box {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 30px;
  }
}
.our-benefits__item {
  width: calc(33.3333333333% - 14px);
}
@media (max-width: 767px) {
  .our-benefits__item {
    width: 100%;
  }
}
.our-benefits__item-header {
  font-weight: 700;
  font-size: 17px;
  line-height: 16px;
  color: #303030;
  margin-bottom: 10px;
}
.our-benefits__item-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #303030;
  margin: 0;
}
.our-benefits__item-icon {
  margin-bottom: 20px;
  min-height: 62px;
}
.our-benefits__item-icon .icon::before {
  font-size: 50px;
  color: #FA7A0A;
}
.our-benefits__item-icon .benefit1 {
  content: url("/image/design/icons/benefit1.svg");
}
.our-benefits__item-icon .benefit4 {
  content: url("/image/design/icons/benefit4.svg");
}

.our-trademarks__title {
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  .our-trademarks__title {
    margin-bottom: 30px;
  }
}
.our-trademarks__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.our-trademarks__item {
  width: calc(20% - 16px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.our-trademarks__item > img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
@media (max-width: 767px) {
  .our-trademarks__item {
    width: calc(50% - 16px);
    padding: 6px;
  }
}
.our-trademarks__slider .slick-track {
  display: flex;
  align-items: center;
}

.our-partners__title {
  margin-bottom: 56px;
}
.our-partners__box img {
  padding: 0 10px;
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .our-partners__box img {
    padding: 0 5px;
  }
}
@media (max-width: 767px) {
  .our-partners__box {
    padding: 0 40px;
  }
}
.our-partners .slick-arrow {
  width: 18px;
  height: 18px;
  border-bottom: 1px solid #7A7A7A;
  border-right: 1px solid #7A7A7A;
  transform: rotate(-45deg);
}
.our-partners .slick-arrow.slick-prev {
  transform: rotate(135deg);
}
@media (max-width: 767px) {
  .our-partners .slick-arrow.slick-prev {
    left: 0;
  }
}
@media (max-width: 767px) {
  .our-partners .slick-arrow.slick-next {
    right: 0;
  }
}

.our-directions {
  background-color: #F0F0F0;
}
.our-directions__title {
  margin-bottom: 20px;
}
.our-directions__description {
  margin: 0 auto 40px;
  max-width: 676px;
}
@media (max-width: 767px) {
  .our-directions__description {
    margin: 0 0 30px;
  }
}
.our-directions__description-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #303030;
}
@media (max-width: 767px) {
  .our-directions__description-text {
    text-align: left;
  }
}
.our-directions__box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
}
.our-directions__box .our-directions__item:nth-child(even) {
  flex-direction: row-reverse;
}
.our-directions__box .our-directions__item:nth-child(even) .our-directions__item-text {
  right: -40px;
}
@media (max-width: 767px) {
  .our-directions__box .our-directions__item:nth-child(even) .our-directions__item-text {
    right: 0;
    top: -30px;
  }
}
@media (max-width: 767px) {
  .our-directions__box .our-directions__item:nth-child(even) {
    flex-direction: column;
  }
}
.our-directions__box .our-directions__item:nth-child(odd) .our-directions__item-text {
  left: -40px;
}
@media (max-width: 767px) {
  .our-directions__box .our-directions__item:nth-child(odd) .our-directions__item-text {
    left: 0;
    top: -30px;
  }
}
@media (max-width: 767px) {
  .our-directions__box {
    gap: 0;
  }
}
.our-directions__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .our-directions__item {
    flex-direction: column;
  }
}
.our-directions__item-img {
  flex-basis: 50%;
}
.our-directions__item-img img {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .our-directions__item-img {
    margin: 0 -15px 0 -15px;
  }
}
.our-directions__item-text {
  flex-basis: 50%;
  padding: 40px;
  background-color: #fff;
  box-shadow: 10px 14px 24px rgba(0, 47, 81, 0.08);
  position: relative;
}
@media (max-width: 767px) {
  .our-directions__item-text {
    padding: 30px 20px;
  }
}
.our-directions__item-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  color: #303030;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.our-directions__item-title::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #FA7A0A;
  flex-shrink: 0;
}
.our-directions__item-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #303030;
}
.our-directions__item-description p:not(:last-child) {
  margin-bottom: 20px;
}

footer {
  background-color: #fff;
  color: #303030;
  padding-top: 40px;
  padding-bottom: 40px;
  border: none;
  margin: 0;
}

.footer__box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}
.footer__img img {
  max-width: 200px;
  height: auto;
}
@media (max-width: 767px) {
  .footer__img {
    flex-basis: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .footer__links {
    flex-basis: calc(50% - 15px);
  }
}
.footer__links-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #303030;
  margin-bottom: 15px;
}
.footer__links-list li:not(:last-child) {
  margin-bottom: 10px;
}
.footer__links-list li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #303030;
}
.footer__links-list li a:hover {
  color: #FA7A0A;
}
@media (max-width: 767px) {
  .footer__btn-block {
    flex-basis: 100%;
    margin-top: 30px;
  }
}
.footer__copywriting {
  margin-top: 20px;
}

.home-about-us,
.home-our-benefits,
.home-our-partners,
.home-our-directions {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .home-about-us,
.home-our-benefits,
.home-our-partners,
.home-our-directions {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.home-our-trademarks {
  padding-top: 120px;
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .home-our-trademarks {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.information-page p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 18px;
  color: #3C3C3C;
}
.information-page a {
  color: #FA7A0A;
  font-size: 14px;
  line-height: 18px;
}

.contacts-page {
  padding: 30px 0;
}
.contacts-page__title {
  margin-bottom: 15px;
}
.contacts-page__list {
  padding-left: 15px;
}
.contacts-page__list-item {
  list-style: none;
}
.contacts-page__list-item:not(:last-child) {
  margin-bottom: 10px;
}
.contacts-page__our-contacts {
  margin-bottom: 30px;
}
.contacts-page__row:not(:last-child) {
  margin-bottom: 20px;
}
.contacts-page__phones {
  display: flex;
  justify-content: space-between;
}
.contacts-page__phones .column {
  width: 50%;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contacts-page__phones .column a {
  font-size: 18px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .contacts-page__phones .column {
    width: 100%;
  }
}
.contacts-page__email a {
  font-size: 18px;
  font-weight: 700;
}