body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $font-family_mariupol_regular;
  color: $black;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  background-color: $white;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.mobile-hidden {
  @media (max-width: $mobile-max) {
    display: none !important;
  }
}