.contacts-page {
  padding: 30px 0;

  &__title {
    margin-bottom: 15px;
  }

  &__list {
    padding-left: 15px;
  }

  &__list-item {
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__our-contacts {
    margin-bottom: 30px;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__phones {
    display: flex;
    justify-content: space-between;

    .column {
      width: 50%;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      a {
        font-size: 18px;
        font-weight: 700;
      }

      @media (max-width: $mobile-max) {
        width: 100%;
      }
    }
  }

  &__email {
    a {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
