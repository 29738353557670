.about-us {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        width: calc(100% / 2);

        @media (max-width: $mobile-max) {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 20px;
    }

    &__description {
        padding: 0 35px;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        @media (max-width: $mobile-max) {
            padding: 0;
        }
    }

    &__img {
        img {
            width: 100%;
            height: auto;
        }
    }
    
    @media (max-width: $mobile-max) {
        flex-direction: column;
        gap: 20px;
    }
}