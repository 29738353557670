.shop-address {
  padding: 15px;
  border: 3px dashed #f5881f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: $mobile-max) {
    flex-direction: column;
  }

  &__info {
    width: 35%;

    @media (max-width: $mobile-max) {
      width: 100%;
    }
  }

  &__map {
    width: 65%;

    @media (max-width: $mobile-max) {
      width: 100%;
    }
  }

  &__city {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 12px;
    color: $black;
  }

  &__address {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 12px;
    color: $black;
  }

  &__phones {
    margin-bottom: 10px;

    .phones-block {
      display: none;

      &.show-phones {
        display: block;
      }
    }

    .get-phones,
    .hidden-phones {
      cursor: pointer;
      color: #f5881f;
      font-weight: 700;
      font-size: 12px;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
    }

    .hidden-phones {
      margin-top: 10px;
      display: none;
    }
  }
}
