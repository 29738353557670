.form-builder {
    border-radius: 8px;
    border: 1px solid $light-gray;
    max-width: $tablet-medium;
    width: 100%;
    margin: 0 auto;
    
    .box-heading {
        padding: 15px;
        text-align: center;
        background-color: $orange;
        border-radius: 8px 8px 0 0;

        .header-text {
            color: #fff;
        }
    }

    .box-content {
        padding: 15px;
        
        .field-block {
            justify-content: space-between;
            align-items: center;

            h4 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .btn {
                font-weight: 700;
                font-size: 16px;
                line-height: 15px;
                text-align: center;
                border-radius: 60px;
                background: $orange;
                background-color: $orange;
                padding: 22px 40px;
                border: 1px solid $orange;
                outline: none;
                color: $white ;
                text-decoration: none;
                display: inline-block;
            
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

#form-builder-61 {
    display: none;

    &.show {
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1000;
        transform: translateX(-50%) translateY(-50%);
        background-color: $white;
        box-shadow: 2px 2px 16px 1px rgb(0, 0, 0, .4);
    }
}