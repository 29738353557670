* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
}